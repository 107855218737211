export const environment = {
    production: false,
    
    apiUrl: 'https://localhost:5001/api/',
    imagens: 'https://almobhml.blob.core.windows.net/website/',
    
    // apiUrl: 'https://gestao-hml.azurewebsites.net/api/',
    // imagens: 'https://almobhml.blob.core.windows.net/website/',

    nome: "local"
};
